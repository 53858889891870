import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import ProductList from 'components/commercetools-ui/products/product-list';
import PinkBulletin from 'components/frontastic-ui/amplience/pink-bulletin';
import { getContentTypeFromSchemaFormated } from 'helpers/utils/amplienceUtils';
import AmplienceDynamicContentTastic from '../amplience-content';

export interface Props {
  content: any;
}

export interface DataSourceContent {
  data: {
    dataSource: {
      content: any;
    };
  };
}

const AmplienceDynamicPage = (props: Props) => {
  const router = useRouter();

  const { items, facets, total, query, queryID, indexName } = props.content?.productsData || {};

  useEffect(() => {
    removeSEOLinks();
    setSEOLinks();

    return () => {
      removeSEOLinks();
    };
  }, [router.asPath]);

  const setSEOLinks = () => {
    const PAGE_SIZE = 36;
    const currentPageNumber = +(router?.query?.page ?? 1);
    const baseUrl = `${window.location.origin}${window.location.pathname}?page=`;

    const SEOLinks = {
      prev: currentPageNumber - 1,
      next: currentPageNumber + 1,
    };

    Object.entries(SEOLinks).forEach((e) => {
      const [linkType, pageNumber] = e;
      if (pageNumber !== 0 && !(linkType === 'next' && currentPageNumber * PAGE_SIZE >= total)) {
        const linkElement = document.createElement('link');
        linkElement.setAttribute('rel', linkType);
        linkElement.setAttribute('href', baseUrl + pageNumber);
        document.querySelector('head').appendChild(linkElement);
      }
    });
  };

  const removeSEOLinks = () => {
    ['prev', 'next'].forEach((linkRel) => {
      document
        .querySelector('head')
        .querySelectorAll(`[rel=${linkRel}]`)
        .forEach((e) => e?.remove());
    });
  };

  const populatePageData = () => {
    const slots = props?.content?.slotsData;
    const components = [];

    slots?.map((slot, index) => {
      const schema = slot?.content?._meta?.schema;
      // Section Layout is a common amplience component used to show grouped amplience components
      if (getContentTypeFromSchemaFormated(schema) === 'sectionLayout') {
        return (
          <>
            {slot?.content?.contentBlocks?.map((contentBlock, i) => {
              const content: DataSourceContent = {
                data: {
                  dataSource: {
                    content: { ...contentBlock, index: index + i },
                  },
                },
              };

              components.push(
                <AmplienceDynamicContentTastic
                  data={content}
                  key={`$${getContentTypeFromSchemaFormated(schema)}-${i}`}
                />,
              );
            })}
          </>
        );
      } else {
        const content: DataSourceContent = {
          data: {
            dataSource: {
              content: { ...slot?.content, index: index },
            },
          },
        };

        components.push(
          <AmplienceDynamicContentTastic
            data={content}
            key={`$${getContentTypeFromSchemaFormated(schema)}-${index}`}
          />,
        );
      }
    });

    if (props.content?.pinkBulletin) {
      components.push(<PinkBulletin content={props.content} />);
    }

    if (props.content?.productsData) {
      components.push(
        <ProductList
          products={items}
          totalProductsNum={total}
          facets={facets}
          query={query}
          queryID={queryID}
          indexName={indexName}
        />,
      );
    }

    return components;
  };

  return <>{populatePageData()}</>;
};

export default AmplienceDynamicPage;
