import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { ArrowRightIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { AmplienceImageCard, AmpliencePageSlotReference } from '@Types/amplience/amplienceComponents';
import classNames from 'classnames';
import NextLink from 'components/common/NextLink';
import { useFormat } from 'helpers/hooks/useFormat';
import { amplienceImageLoader } from 'helpers/imageLoaders/amplienceImageLoader';
import { createImageUrlFromAmplienceImageCard } from 'helpers/utils/amplienceUtils';
import { fetchContentById, fetchBulletinArticles } from 'frontastic/actions/amplience';
import { LayoutContext } from 'frontastic/provider/layout';
import AmpliencePinkBulletinBanner from '../amplience-pink-bulletin-banner';
import styles from './pink-bulletin.module.scss';

export interface PinkBulletinCategories {
  categoryList: [
    {
      categoryId: string;
      categoryName: string;
    },
  ];
}

export interface PinkBulletinArticles {
  content: {
    title: string;
    description: string;
    date: string;
    image: AmplienceImageCard;
    categoryId: string;
    page: AmpliencePageSlotReference;
  };
}

export interface AmplienceFilterPageResponse {
  responseCount: number;
  nextCursor: string;
}

export interface PinkBulletinProps {
  content: {
    pinkBulletin: {
      categories: PinkBulletinCategories;
      articles: [PinkBulletinArticles];
      page: AmplienceFilterPageResponse;
    };
  };
}

const PinkBulletin: FC<PinkBulletinProps> = ({ content }) => {
  const router = useRouter();
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>('');
  const [articles, setArticles] = useState<PinkBulletinArticles[]>(content.pinkBulletin.articles);
  const [page, setpage] = useState<AmplienceFilterPageResponse>(content.pinkBulletin.page);
  const [categoriesOpen, setCategoriesOpen] = useState<boolean>(false);
  const { formatMessage } = useFormat({ name: 'common' });
  const layout = useContext(LayoutContext);
  const [articlePathMap, setArticlePathMap] = useState([]);
  const [isArticlePathMapLoaded, setIsArticlePathMapLoaded] = useState<boolean>(false);

  useEffect(() => {
    selectedCategoryId ? fetchFilteredArticles(selectedCategoryId, true) : setArticles(content.pinkBulletin.articles);
  }, [selectedCategoryId]);

  const fetchFilteredArticles = async (categoryId?: string, changedFilter = false) => {
    const filters = categoryId
      ? [
          {
            path: '/categoryId',
            value: categoryId,
          },
        ]
      : [];

    await fetchBulletinArticles(filters, page?.nextCursor).then((response) => {
      changedFilter
        ? setArticles(response.data.responses)
        : setArticles((prevValue) => [...prevValue, ...response.data.responses]);
      setpage(response.data.page);
    });
  };

  function articleImageLoader({ src, width }) {
    return amplienceImageLoader('General', src, width);
  }

  const slotPagePath = (deliveryKey: string) => {
    const segments = deliveryKey.split('/');
    const locale = {
      'en-GB': 'uk',
      'en-US': 'us',
    }[segments.shift()];
    const path = segments.join('/');
    return path;
  };

  async function createArticlePathMap() {
    const updatedArticlePathMap = { ...articlePathMap };
    for (const article of articles) {
      const pageSlotId = article.content.page.id;
      const slotDeliveryKey = article.content.page.slotDeliveryKey;
      const path = slotPagePath(slotDeliveryKey);
      updatedArticlePathMap[pageSlotId] = path;
    }
    setArticlePathMap(updatedArticlePathMap);
    setIsArticlePathMapLoaded(true);
  }

  useEffect(() => {
    createArticlePathMap();
  }, [articles]);

  useMemo(createArticlePathMap, []);

  const pinkArticleCard = (article) => {
    const date = new Date(article.date);
    const path = articlePathMap[article.page.id];
    return (
      <NextLink key={article._meta.deliveryId} className={styles.articleCard} href={`/${path}`}>
        <div className={styles.articleCard__imageWrapper}>
          <Image
            src={createImageUrlFromAmplienceImageCard(article.image)}
            alt={article.image.metaTextAleternative}
            layout="fill"
            loader={articleImageLoader}
            className={styles.articleCard__imageWrapper__image}
          />
        </div>
        <span className={styles.articleCard__date}>
          {article.date ? date.toLocaleDateString('en-GB').replace(/\//g, '.') : ''}
        </span>
        <span className={styles.articleCard__title}>{article.title}</span>
        <span className={styles.articleCard__description}>{article.description}</span>
        <button className={styles.articleCard__readmore}>
          {formatMessage({ id: 'read.more', defaultMessage: 'Read More' })}
          <ArrowRightIcon className={styles.articleCard__readmore__arrow} />
        </button>
      </NextLink>
    );
  };

  const categories = () => {
    return content?.pinkBulletin?.categories?.categoryList?.map((category) => (
      <span
        key={category.categoryId}
        className={classNames(styles.category, {
          [styles.category__selected]: category.categoryId === selectedCategoryId,
        })}
        onClick={() => {
          setCategoriesOpen(false);
          setpage(null);
          selectedCategoryId === category.categoryId
            ? (setSelectedCategoryId(''), setpage(content.pinkBulletin.page))
            : setSelectedCategoryId(category.categoryId);
        }}
      >
        {category.categoryName}
      </span>
    ));
  };
  return (
    isArticlePathMapLoaded && (
      <>
        <div className={styles.banner}>
          {articles[0] && (
            <AmpliencePinkBulletinBanner
              content={articles[0]?.content}
              navigateToArticlePage={articlePathMap[articles[0]?.content?.page?.id]}
            />
          )}
        </div>
        <div className={styles.categoriesContainer}>
          <div className={styles.categories}>{categories()}</div>
        </div>
        <div className={styles.pinknBulletinWrapper}>
          <div className={styles.articlesContainer}>
            {articles && articles?.map((article) => pinkArticleCard(article.content))}
          </div>
          {page?.nextCursor && (
            <button
              className={styles.fetchMore}
              onClick={() => {
                fetchFilteredArticles();
              }}
            >
              {formatMessage({ id: 'load.more.articles', defaultMessage: 'Load _ more articles' }).replace(
                '_',
                page?.responseCount.toString(),
              )}
            </button>
          )}
        </div>
      </>
    )
  );
};

export default PinkBulletin;
