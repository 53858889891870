import { useContext, useEffect, useState } from 'react';
import Image from 'next/image';
import { ArrowRightIcon } from '@heroicons/react/outline';
import { AmplienceImageCard } from '@Types/amplience/amplienceComponents';
import NextLink from 'components/common/NextLink';
import { RENDER_PRIORITY_TRESHOLD } from 'helpers/constants/amplienceConstants';
import { useFormat } from 'helpers/hooks/useFormat';
import { amplienceImageLoader } from 'helpers/imageLoaders/amplienceImageLoader';
import { createImageUrlFromAmplienceImageCard } from 'helpers/utils/amplienceUtils';
import { LayoutContext } from 'frontastic/provider/layout';
import styles from './amplience-pink-bulletin-banner.module.scss';

export interface PinkBulletinBannerProps {
  content: {
    date: string;
    description: string;
    image: AmplienceImageCard;
    linkUrl?: string;
    title: string;
    _meta?: any;
    index?: number;
  };
  navigateToArticlePage?: string;
}

const AmpliencePinkBulletinBanner = (props: PinkBulletinBannerProps) => {
  const [formatedDate, setFormatedDate] = useState('');
  const { formatMessage } = useFormat({ name: 'common' });
  const layout = useContext(LayoutContext);

  useEffect(() => {
    const date_parts = props.content.date.split('-');
    const year = date_parts[0];
    const month = date_parts[1];
    const day = date_parts[2];
    setFormatedDate(day + '.' + month + '.' + year);
  }, [props.content.date]);

  return (
    <div className={styles.bannerContainer} data-testid="pink-bulletin-banner-container">
      <div className={styles.textSection}>
        <h1 className={styles.heading}>{formatMessage({ id: 'pink.bulletin', defaultMessage: 'Pink Bulletin' })}</h1>
        <div className={styles.titleAndDescription}>
          <p className={styles.date}>{formatedDate}</p>
          <p className={styles.title}>{props.content.title}</p>
          {!layout.isMobile && (
            <p className={styles.description} data-testid="pink-bulletin-banner-description">
              {props.content.description}
            </p>
          )}
          <div className={styles.linkWrapper}>
            {(props.content.linkUrl || props.navigateToArticlePage) && (
              <NextLink href={props.content.linkUrl ?? props.navigateToArticlePage} className={styles.link}>
                <div data-testid="pink-bulletin-banner-article-button">
                  {formatMessage({ id: 'read.more', defaultMessage: 'Read More' }).toUpperCase()}
                </div>
              </NextLink>
            )}
            <ArrowRightIcon width={11} />
          </div>
        </div>
      </div>
      <div className={styles.imageSection}>
        {props.content?.index <= RENDER_PRIORITY_TRESHOLD ? (
          <Image
            priority
            {...{ fetchpriority: 'high' }}
            className={styles.image}
            src={createImageUrlFromAmplienceImageCard(props.content.image)}
            alt={props.content.image?.metaTextAleternative ?? ''}
            layout="fill"
            sizes={layout.isDesktop ? '50vw' : '100vw'}
            loader={({ src, width }) => amplienceImageLoader('General', src, width)}
            data-testid="pink-bulletin-banner-image"
          ></Image>
        ) : (
          <Image
            className={styles.image}
            src={createImageUrlFromAmplienceImageCard(props.content.image)}
            alt={props.content.image?.metaTextAleternative ?? ''}
            layout="fill"
            sizes={layout.isDesktop ? '50vw' : '100vw'}
            loader={({ src, width }) => amplienceImageLoader('General', src, width)}
            data-testid="pink-bulletin-banner-image"
          ></Image>
        )}
      </div>
    </div>
  );
};

export default AmpliencePinkBulletinBanner;
